import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';

import { useTranscriptionServer, StreamingRecognizeResponse } from "./useTranscriptionServer";

function App() {
  const [language, setLanguage] = useState<null | string>(null);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  const [readyState, result] = useTranscriptionServer("jwt", mediaStream, language);

  const transcriptionsOn = language != null;
  useEffect(() => {
    if (transcriptionsOn) {
      let subscribed = true;
      const promise = navigator.mediaDevices.getUserMedia({ audio: { noiseSuppression: false } })

      promise.then(
        (value) => {
          if (subscribed) {
            setMediaStream(value);
          }
        },
        (error) => {
          window.alert(error.message)
        }
      );

      return () => {
        subscribed = false;

        promise.then(
          (value) => {
            value.getTracks().forEach((track) => {
              track.stop();
            });
            setMediaStream(null);
          }
        );
      }
    }
  }, [transcriptionsOn]);

  return (
    <div className="App">

      <div className="options">
        <span>
          {language === "en" ? (<span>English</span>) : (<a href="#" onClick={() => { setLanguage("en"); return false; }}>English</a>)}
        </span>
        <span>
          {language === "ko" ? (<span>Korean</span>) : (<a href="#" onClick={() => { setLanguage("ko"); return false; }}>Korean</a>)}
        </span>
        <span>
          {language === null ? (<span>off</span>) : (<a href="#" onClick={() => { setLanguage(null); return false; }}>off</a>)}
        </span>
      </div>

      <div className="transcription">
        {result != null && (
          recognizeResponseToString(result.response)
        )}
      </div>
    </div>
  );
}

function recognizeResponseToString(response: StreamingRecognizeResponse) {
  if (Array.isArray(response.results)) {
    return response.results.map(result => {
      if (result.alternatives != null && result.alternatives.length > 0) {
        return result.alternatives[0].transcript;
      } else {
        return "";
      }
    }).join(" ");
  } else {
    return "";
  }
}

export default App;
